const OneToFiveBlock = () => import('portal/components/common/content-block/OneToFiveBlock.vue');
const OneToTwoBlock = () => import('portal/components/common/content-block/OneToTwoBlock.vue');
const OneToFourRightBlock = () => import('portal/components/common/content-block/OneToFourRightBlock.vue');
const OneToFourBlock = () => import('portal/components/common/content-block/OneToFourBlock.vue');
const ThreeToThreeBlock = () => import('portal/components/common/content-block/ThreeToThreeBlock.vue');
const VacancyBlock = () => import('portal/components/common/vacancy/VacancyBlock.vue');
const PartnersBlock = () => import('portal/components/pages/home/PartnersBlock.vue');
const EventsBlock = () => import('portal/components/pages/home/HomeEvents');
const ZeroToFourBlock = () => import('portal/components/common/content-block/ZeroToFourBlock.vue');
const HomeRating = () => import('portal/components/pages/home/HomeRating');
const ZeroToThreeBlock = () => import('portal/components/common/content-block/ZeroToThreeBlock.vue');
const adsBlock = () => import('portal/components/common/ads/AdsBlock.vue');
const AdsToThreeBlock = () => import('portal/components/common/content-block/AdsToThreeBlock.vue');
const YandexAdBlock = () => import('portal/components/common/ads/YandexAdBlock.vue');

export default {
    onePlusFourRight: OneToFourRightBlock,
    onePlusFive: OneToFiveBlock,
    onePlusTwo: OneToTwoBlock,
    onePlusFour: OneToFourBlock,
    threePlusThree: ThreeToThreeBlock,
    vacancy: VacancyBlock,
    partners: PartnersBlock,
    events: EventsBlock,
    zeroPlusFour: ZeroToFourBlock,
    rating: HomeRating,
    ads: adsBlock,
    adsPlusThree: AdsToThreeBlock,
    zeroPlusThree: ZeroToThreeBlock,
    yandexAd: YandexAdBlock,
};
